<template>
  <div class="p-3">
    <div class="overflow-auto">
      <div>
        <button
          class="btn btn-sm btn-outline-success shadow-none m-1"
          @click="add"
          v-if="!arch"
        >
          добавить запись
        </button>
        <button
          class="btn btn-sm btn-outline-secondary shadow-none m-1"
          @click="get_arch"
        >
          {{ !arch ? "архив" : "назад" }}
        </button>
        <button
          class="btn btn-sm btn-outline-primary shadow-none m-1"
          @click="get_table"
          v-if="!arch"
        >
          таблица
        </button>
      </div>
      <div v-if="visible_table">
        <table class="table table-sm table-responsive table-bordered">
          <tr>
            <th>контрагент</th>
            <th>№ реализации</th>
            <th>сумма</th>
            <th>тк</th>
            <th>--------</th>
            <th>доки</th>
          </tr>
          <tr v-for="(item, idx) in table_for_pavlov" :key="idx + 'p'">
            <td>{{ item.user }}</td>
            <td>{{ item.number_realiz }}</td>
            <td>{{ item.summa }}</td>
            <td>{{ item.tk }}</td>
            <td></td>
            <td>{{ item.doki }}</td>
          </tr>
        </table>
      </div>
      <table class="table table-sm table-hover table-bordered">
        <tr>
          <th>Заказ №</th>
          <th>Контрагент</th>
          <th>№ реализации</th>
          <th>сумма</th>
          <th>ТК</th>
          <th>На сборку</th>
          <th>Собран</th>
          <th>Доки</th>
          <th>дата отправки</th>
          <th colspan="2">Комментарий</th>
          <th v-if="!arch"></th>
        </tr>
        <tbody v-if="list.otpravki">
          <tr
            v-for="(item, idx) in list.otpravki"
            :key="idx"
            @dblclick="edit(idx)"
            :class="[`text-${item.class}`, !arch && 'cursor']"
          >
            <td class="t">{{ item.zakaz }}</td>
            <td class="t text-nowrap">{{ item.user }}</td>
            <td class="t">{{ item.number_realiz }}</td>
            <td class="t text-nowrap">{{ item.summa }}</td>
            <td class="t text-nowrap">{{ item.tk }}</td>
            <td class="t">{{ item.otdan_na_sborku }}</td>
            <td class="t">{{ item.sobran }}</td>
            <td class="t">{{ item.doki }}</td>
            <td class="t">{{ item.data_otpravki }}</td>
            <td class="t" colspan="2">{{ item.komment }}</td>
            <td v-if="!arch" class="text-nowrap no-t">
              <button
                class="btn btn-sm btn-outline-secondary m-1 shadow-none"
                @click="set_sobran(item.id)"
              >
                С
              </button>
              <button
                class="btn btn-sm btn-outline-primary m-1 shadow-none"
                @click="set_doki(item.id)"
              >
                Д
              </button>
              <button
                class="btn btn-sm btn-outline-success m-1 shadow-none"
                @click="set_send(item.id)"
              >
                уехал
              </button>
              <button
                class="btn btn-sm btn-outline-danger m-1 shadow-none"
                @click="delete_item(item.id)"
              >
                удалить
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <span data-toggle="modal" data-target="#exampleModal" ref="modal"> </span>

    <!-- Модальное окно -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="text-center w-100 font-weight-bolder">
              {{ modal_title }}
            </div>
          </div>
          <div class="modal-body">
            <div class="m-1">
              <small class="form-text text-muted ml-1">номер заказа</small>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="n.zakaz"
              />
            </div>
            <div class="m-1">
              <small class="form-text text-muted ml-1">контрагент</small>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="n.user"
                size="1"
              />
            </div>
            <div class="m-1">
              <select
                v-if="list.users"
                class="form-control form-control-sm shadow-none"
                v-model="n.user"
              >
                <option value="">выбор контрагента</option>
                <option
                  v-for="(item, idx) in list.users"
                  :key="idx + 'u'"
                  :value="item"
                  >{{ item }}</option
                >
              </select>
            </div>
            <div class="m-1">
              <small class="form-text text-muted ml-1">номер реализации</small>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="n.number_realiz"
                size="1"
              />
            </div>
            <div class="m-1">
              <small class="form-text text-muted ml-1">сумма</small>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="n.summa"
                size="1"
              />
            </div>
            <div class="m-1">
              <small class="form-text text-muted ml-1">тк</small>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="n.tk"
                size="1"
              />
            </div>
            <div class="m-1">
              <select
                v-if="list.tk"
                class="form-control form-control-sm shadow-none"
                v-model="n.tk"
              >
                <option value="">выбор тк</option>
                <option
                  v-for="(item, idx) in list.tk"
                  :key="idx + 't'"
                  :value="item"
                  >{{ item }}</option
                >
              </select>
            </div>
            <div class="m-1">
              <small class="form-text text-muted ml-1">на сборку</small>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="n.otdan_na_sborku"
                size="1"
              />
            </div>
            <div class="m-1">
              <small class="form-text text-muted ml-1">собран</small>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="n.sobran"
                size="1"
                @click="set_data_sobran"
              />
            </div>
            <div class="m-1">
              <small class="form-text text-muted ml-1">доки</small>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="n.doki"
                size="1"
                @click="set_data_doki"
              />
            </div>
            <div class="m-1">
              <small class="form-text text-muted ml-1">комментарий</small>
              <textarea
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="n.komment"
                rows="3"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary shadow-none"
              data-dismiss="modal"
              ref="close"
              @click="close_modal"
            >
              Закрыть
            </button>
            <button
              type="button"
              class="btn btn-outline-primary shadow-none"
              @click="add_new_otpravka"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
    <Loader v-if="loader" />
  </div>
</template>
<script>
import { HTTPGET, HTTPGETNOLOADER } from "@/api";
import Loader from "@/components/Loader";
export default {
  name: "Otpravki",
  components: { Loader },
  mounted() {
    this.get_data(null, HTTPGET);
  },
  data: () => ({
    list: [],
    visible_add: false,
    table_for_pavlov: [],
    visible_table: false,
    arch: false,
    loader: false,
    modal_title: "",
    n: {
      zakaz: "",
      user: "",
      number_realiz: "",
      summa: "",
      tk: "",
      otdan_na_sborku: "",
      sobran: "",
      doki: "",
      komment: "",
      edit_id: null
    }
  }),
  methods: {
    get_data(type, met) {
      met === HTTPGETNOLOADER ? (this.loader = false) : (this.loader = true);
      if (type === "arch") {
        this.arch = !this.arch;
        if (!this.arch) {
          type = null;
        }
      }
      met({
        url: "/admin/otpravki/get_data.php",
        params: {
          type
        }
      })
        .then(response => {
          this.list = response.data;
          this.n.zakaz = response.data.last_zakaz;
          this.n.otdan_na_sborku = response.data.data;
        })
        .then(() => (this.loader = false));
    },
    add() {
      this.modal_title = "новая запись";
      this.$refs.modal.click();
    },
    set_data_doki() {
      this.n.doki = this.n.otdan_na_sborku;
    },
    set_data_sobran() {
      this.n.sobran = this.n.otdan_na_sborku;
    },
    add_new_otpravka() {
      HTTPGET({
        url: "/admin/otpravki/add_new_otpravka.php",
        params: this.n
      })
        .then(() => this.$refs.close.click())
        .then(() => {
          (this.n.zakaz = ""),
            (this.n.user = ""),
            (this.n.number_realiz = ""),
            (this.n.summa = ""),
            (this.n.tk = ""),
            (this.n.otdan_na_sborku = ""),
            (this.n.sobran = ""),
            (this.n.doki = ""),
            (this.n.edit_id = null),
            (this.n.komment = "");
        })
        .then(() => this.get_data(null, HTTPGET));
    },
    set_sobran(id) {
      HTTPGET({
        url: "/admin/otpravki/action.php",
        params: {
          id: id,
          action: "sobran"
        }
      }).then(() => this.get_data(null, HTTPGET));
    },
    set_doki(id) {
      HTTPGET({
        url: "/admin/otpravki/action.php",
        params: {
          id: id,
          action: "doki"
        }
      }).then(() => this.get_data(null, HTTPGET));
    },
    set_send(id) {
      if (confirm("точно уехал?")) {
        HTTPGET({
          url: "/admin/otpravki/action.php",
          params: {
            id: id,
            action: "send"
          }
        }).then(() => this.get_data(null, HTTPGET));
      }
    },
    delete_item(id) {
      if (confirm("точно удалить?")) {
        HTTPGET({
          url: "/admin/otpravki/action.php",
          params: {
            id: id,
            action: "delete"
          }
        }).then(() => this.get_data(null, HTTPGET));
      }
    },
    get_table() {
      this.visible_table = !this.visible_table;
      HTTPGET({
        url: "/admin/otpravki/get_table_for_pavlov.php"
      }).then(response => (this.table_for_pavlov = response.data.table));
    },
    close_modal() {
      (this.n.zakaz = ""),
        (this.n.user = ""),
        (this.n.number_realiz = ""),
        (this.n.summa = ""),
        (this.n.tk = ""),
        (this.n.otdan_na_sborku = ""),
        (this.n.sobran = ""),
        (this.n.doki = ""),
        (this.n.edit_id = null),
        (this.n.komment = "");
      this.get_data(null, HTTPGETNOLOADER);
    },
    edit(idx) {
      if (this.arch) {
        return;
      }
      this.n.zakaz = this.list.otpravki[idx].zakaz;
      this.n.user = this.list.otpravki[idx].user;
      this.n.number_realiz = this.list.otpravki[idx].number_realiz;
      this.n.summa = this.list.otpravki[idx].summa;
      this.n.tk = this.list.otpravki[idx].tk;
      this.n.otdan_na_sborku = this.list.otpravki[idx].otdan_na_sborku;
      this.n.sobran = this.list.otpravki[idx].sobran;
      this.n.doki = this.list.otpravki[idx].doki;
      this.n.edit_id = this.list.otpravki[idx].id;
      this.n.komment = this.list.otpravki[idx].komment;
      this.modal_title = "редактирование";
      this.$refs.modal.click();
    },
    get_arch() {
      this.get_data("arch", HTTPGET);
    }
  }
};
</script>
<style scoped>
th,
td {
  font-size: 0.8em;
  vertical-align: middle;
}
th {
  text-align: center;
  font-size: 0.7em;
}
button {
  padding: 1px 6px;
  margin: 2px;
  font-size: 0.9em;
}
.t {
  padding: 2px;
}
.no-t {
  padding: 0;
}
.cursor {
  cursor: pointer;
}
.table-bordered {
  border: 0;
}
</style>
